




































































































import { Component, Vue } from 'vue-property-decorator'
@Component({
    components: {
        headerSection: () => import("./Header.vue"),
        footerSection: () => import("./Footer.vue"),
    },
    mounted(): void {
        // this.setAppVertions()
    },
})
export default class Promotion extends Vue {}
