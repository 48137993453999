<template>
  <div>
     <div class="home">
        <modal name="login"
                :width="300"
                :height="300"
                :adaptive="true">
                <div class="boxlogin">
                    <div id="bp-left" class="box-part">
                        <div id="partition-register" class="partition">
                            <div class="partition-title">LOGIN ACCOUNT</div> 
                            <div class="partition-form">
                                <form autocomplete="false">
                                    <input class="form-control" type="text" placeholder="รหัส" v-model="username">
                                    <input class="form-control" style="margin-top: 16px;" type="password" placeholder="Password" v-model="password">
                                </form> 
                                <div style="margin-top: 42px;"></div> 
                                <div class="button-set" style="display: flex">
                                    <button id="goto-signin-btn" style="min-width: 126px; margin-right: 4px" @click="login()">Log In</button> <button style="min-width: 126px; margin-left: 4px;" id="register-btn" @click="gotoline()">Register</button>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
        </modal>
        <div class="gl-header">
          <nav class="navbar navbar-fixed-top">
            <div class="container">
              <div id="site-logo" class="navbar-header" style="width:100%;margin-left: 0px;"><!-- align-items: center;-->
                <a class="navbar-brand" @click="goto('home')" title="back to home page">
                  <img src="https://firebasestorage.googleapis.com/v0/b/jk-premium.appspot.com/o/images%2Fgl-logo.png?alt=media" alt="PREMIUM JOKER">
                </a>
                <div style="flex: 1"></div>
                <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px;" @click="refreshBalance">
                  <!--
                  <div class="-inner-box-wrapper">
                    <img class="img-fluid -ic-coin" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                    <span id="customer-balance"><span class="text-green-lighter">104.00</span></span>
                  </div>
                  <button type="button" class="-btn-balance" id="btn-customer-balance-reload" data-loading="_onReloadBalance_" data-done="_onReloadBalanceDone_" data-ajax-href="/account/_ajax_/get-balance" data-target="#customer-balance">
                    <i class="fa fa-sync-alt f-9"></i>
                  </button>
                  -->
                  <div style="display: flex; align-items: center;justify-content:flex-end">
                    <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                      <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                      <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 10px 4px 6px; font-size: 14px;">{{balance}}</span>
                    </div>
                    <i :class="getSpinClass()" style="color: #FFF; font-size: 12px; margin-left: 4px;"></i>
                  </div>
                  <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                    <span  style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
		    </div>
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-1 col-1 text-white iconsize text-center" style=" top: 10px;">
                    </div>
                </div>
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="s005">
                            <form>
                            <fieldset>
                                <div class="inner-form">
                                <div class="input-field">
                                    <input class="form-control" @keyup="searchData" v-model.trim="search" id="choices-text-preset-values" type="text" placeholder="Type to search..." />
                                </div>
                                </div>
                            </fieldset>
                            </form>
                        </div>
                        </div>
                    </div>
                    <div id="row">
                        <div class="buttoncontainer grid grid--auto-tab">
                            <div class="grid__tab bt-favorite" @click="showGames('Favorite')"><img src="../assets/images/favorite150.png" alt="" class="img-responsive" style="height: 75px;margin-top: -5px;"></div>
                            <div class="grid__tab button" @click="showGames()"><img class="img-responsive" src="../assets/images/01tab-all.png" alt="เกมส์ทั้งหมด" /></div>
                            <div class="grid__tab button" @click="showGames('Slot')"><img class="img-responsive" src="../assets/images/02tab-slot.png" alt="สล็อตแมชชีน" /></div>
                            <div class="grid__tab button" @click="showGames('Fishing')"><img class="img-responsive" src="../assets/images/03tab-fish.png" alt="ยิงปลา" /></div>
                            <div class="grid__tab button" @click="showGames('Funky')"><img class="img-responsive" src="../assets/images/04tab-funky.png" alt="ขี้ขลาด" /></div>
                            <div class="grid__tab button" @click="showGames('Bingo')"><img class="img-responsive" src="../assets/images/05tab-bingo.png" alt="ปิงโก" /></div>
                            <div class="grid__tab button" @click="showGames('ECasino')"><img class="img-responsive" src="../assets/images/06tab-ecasino.png" alt="อีเกมส์" /></div>
                        </div>
                    </div>
                    <div class="box" style="padding-bottom: 85px;"><!-- start row -->
    <!-- Start All Group -->
                        <div id="All" class="grid grid--auto-fit panel" style="justify-content: center;">
                          <div class="grid__item" v-for="game in games" :key="game.GameCode">
                                <div class="joker-box-game size">
                                  <div style="position: absolute; right: 8px;" @click="favorite(game.GameCode)">
                                      <img src="../assets/images/favorite.png" :style="checkCss(game.GameCode)" width="28px;">
                                  </div>
                                  <a href="javascript:void(0)" @click="openGame(game.GameCode)">
                                    <img class="img-responsive" :src="'https:'+game.Image2" alt="Fish World" />
                                  </a>
                                  <div>
                                  <p class="text-center" v-if="game.Specials">
                                    🔥<font size="2">{{game.GameName}}</font>
                                  </p>
                                  <p class="text-center" v-else>
                                    <i class="fa fa-gamepad"></i><font size="2">{{game.GameName}}</font>
                                  </p>
                                  </div>
                                    <div class="game-container" v-if="game.jackpot"><!-- Start coinAnimate -->
                                        <div class="index-jackpot jackpot showing-jackpot has-value" data-game-code="TsaiShengGift" data-jackpot-group="JPGrand">
                                            <div class="jackpot-value-container">
                                                <div data-point="0" class="jackpot-value" v-if="game.jackpot">{{numberWithCommas(game.jackpot)}}</div>
                                            </div>
                                            <div class="jackpot-background"></div>
                                            <div class="jackpot-coin"></div>
                                        </div>
                                    </div><!-- End coinAnimate -->
                                </div>
                            </div>
                            <!-- <div class="grid__item" v-for="game in games" :key="game.GameCode">
                                <div class="joker-box-game size">
                                  <div style="position: absolute; right: 8px;" @click="favorite(game.GameCode)">
                                      <img src="../assets/images/favorite.png" :style="checkCss(game.GameCode)" width="28px;">
                                  </div>
                                  <a href="javascript:void(0)" @click="openGame(game.GameCode,game.GameType)">
                                    <img class="img-responsive" :src="'https:'+game.Image2" alt="Fish World" />
                                  </a>
                                  <div>
                                  <p class="text-center" v-if="game.Specials">
                                    🔥<font size="2">{{game.GameName}}</font>
                                  </p>
                                  <p class="text-center" v-else>
                                    <i class="fa fa-gamepad"></i><font size="2">{{game.GameName}}</font>
                                  </p>
                                  </div>
                                    <div class="game-container" v-if="game.jackpot">
                                        <div class="index-jackpot jackpot showing-jackpot has-value" data-game-code="TsaiShengGift" data-jackpot-group="JPGrand">
                                            <div class="jackpot-value-container">
                                                <div data-point="0" class="jackpot-value" v-if="game.jackpot">{{numberWithCommas(game.jackpot)}}</div>
                                            </div>
                                            <div class="jackpot-background"></div>
                                            <div class="jackpot-coin"></div>
                                        </div>
                                    </div><
                                </div>
                            </div> -->
                        </div><!-- End ECasino Group -->
                    </div><!-- end row -->
                </div>
            </div>
	      </main>
<!--
        <div class="x-button-actions" id="account-actions-mobile" style="position: fixed; left: 0; right: 0; bottom: 0;">
                <div class="d-flex">
                    <div class="-btn-container -withdraw-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="EZ Slot ถอนเงิน" class="-icon img-fluid">
                            <div class="-typo">ถอนเงิน</div>
                        </a>
                    </div>
                    <div class="-btn-container -lobby-container">
                        <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
                            <div class="-img-container">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                            </div>
                            <div class="-typo">หน้าเกมส์</div>
                        </a>
                    </div>
                    <div class="-btn-container -deposit-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#depositChoosePromotionModal" class="btn btn-lg btn-plain-secondary js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="EZ Slot ฝากเงิน" class="-icon img-fluid">
                            <div class="-typo">ฝากเงิน</div>
                        </a>
                    </div>
                </div>
            </div>
-->

        <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;" @click="goto('withdraw')">
            <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="ถอนเงิน" style="width: 60px; height: 58px; margin-top: -28px;">
            <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ถอนเงิน</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;" @click="goto('home')">
            <div>
                <img class="animate__animated animate__pulse animate__infinite" src="https://firebasestorage.googleapis.com/v0/b/jk-premium.appspot.com/o/images%2Ficon-pmjk-1-1.png?alt=media" style="width: 105px; height: 105px; margin-top: -22px;" alt="เข้าเล่น Lobby">
            </div>
            <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;" @click="goto('deposit')">
            <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="ฝากเงิน" style="width: 60px; height: 58px; margin-top: -28px;">
             <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ฝากเงิน</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import "../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../assets/fonts/iconic/css/material-design-iconic-font.min.css";
import "../assets/css/main.css";
import "../assets/css/util.css";
import firebase, { database } from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import 'firebase/auth';
require('vue2-animate/dist/vue2-animate.min.css')
import _ from 'lodash';
//const axios = require("axios").default;
import moment from "moment-timezone";
const axios = require("axios").default;
// @ is an alias to /src
export default {
  name: "PlayGame",
  mounted() {
    if (!firebase.auth().currentUser) {
      this.$modal.show('login')
    }else{
        //firebase.auth().signOut()
        this.getUserId()
    }
    firebase.database().ref(`/games2`).once('value').then((snapshot) => {
        const game = []
        const games = []
        snapshot.forEach((doc) => {
            games.push(doc.val())
            game[doc.val().GameType] = doc.val().GameType
        });
        this.games = _.orderBy(games, ['GameName'],['asc'])
        this.gamesAll = _.orderBy(games, ['GameName'],['asc'])
    });
  },
  data() {
    return {
        code:'',
        token:'',
        gamesAll:[],
        games:[],
        search:'',
        username: "",
        password: "",
        loading: false,
        userId: "",
        error: false,
        message: "Invalid user name or password!",
        balance:0.00,
        favorites:[],
        username:'',
        password:'',
        animationInfinite: {
          classes: 'fadeIn',
          duration: 5000,
          iteration: 'infinite'
        },
    };
  },
  methods: {
    async getUserId(){
        const email = firebase.auth().currentUser.email.split('@');
        const users = (await firebase.database().ref(`/users`).orderByChild('code').equalTo(email[0]).once('value')).val();
        const user = users[Object.keys(users)[0]]
        const key = Object.keys(users)[0]
        if(user.calculate ==='Y'){
            this.userId = user.userId
            this.code = user.code
            this.refreshBalance();
            firebase.database().ref(`/favorites/${this.userId}`).once('value').then((snapshot) => {
                snapshot.forEach((doc) => {
                    this.favorites.push(doc.key);
                });
            });
            firebase.database().ref(`/users/${key}`).update({
                lastlogin: moment().tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss"),
            });
            firebase.firestore().collection('login').doc(this.userId+'-'+moment().tz("Asia/Bangkok").format("YYYY-MM-DD")).set({
                login:moment().tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss"),
                userId:this.userId,
                code:this.code,
            });
        }else{
           this.$modal.show('login');
        }
    },
    login(){
        if(this.username && this.password){
            firebase.auth().signInWithEmailAndPassword(this.username.trim().toLowerCase()+'@premiumjoker.com', this.password.trim()).then(async (res) => {
                this.getUserId();
            });
            this.$modal.hide('login')
        }
    },
    gotoline(){
        window.location = 'https://lin.ee/lgMF2P8';
    },
    checkCss(code){
      const index = _.findIndex(this.favorites, function(o) { return o == code; });
      if(index>-1){
        return ``;
      }else{
        return `filter: grayscale(100%);`;
      }
    },
    favorite(gameCode){
      const index = _.findIndex(this.favorites, function(o) { return o == gameCode; });
      if(this.userId){
        if(index>-1){
            firebase.database().ref('favorites/'+this.userId+'/'+gameCode).remove();
            this.favorites.splice(index, 1);
        }else{
            firebase.database().ref('favorites/'+this.userId+'/'+gameCode).push({
                gameCode
            });
            this.favorites.push(gameCode);
        }
      }
    },
    goto(type){
      if(type === 'home'){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }else if(type==='withdraw'){
        //window.location = 'https://liff.line.me/1655948463-xjAo1q7p/withdraw';
        this.gotoline()
      }else if(type==='deposit'){
        //window.location = 'https://liff.line.me/1655948463-xjAo1q7p/deposit';
        this.gotoline()
      }
    },
    getSpinClass(){
      if(!this.loading)
        return `fas fa-sync-alt f-9`;
      else
        return  `fas fa-sync-alt fa-spin f-9`;
    },
    async openGame(code){
      const token = await this.getTokenPlayGame();
      if(token){
          window.location=`http://www.gwc688.net?token=${token}&game=${code}&redirectUrl=https://premiumjoker.com/playgame&mobile=true&lang=en`
      }else{
        this.$modal.show('login');
      }
    },
    numberWithCommas(x) {
      return Number(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getTokenPlayGame(){
      if(this.userId){
        await axios({
          method: "post",
          url: "https://api.premiumjoker.com/gettoken",
          data: {
            id: this.userId
          }
        }).then(async response => {
          if (response.data && response.data.status) {
            this.token = response.data.data.Token;
          }
        });
        return this.token;
      }
    },
    refreshBalance(){
      if(this.userId){
        this.loading = true;
        axios({
          method: "post",
          url: "https://api.premiumjoker.com/getcredit",
          data: {
            id: this.userId
          }
        }).then(async response => {
          if (response.data && response.data.status) {
            this.balance = this.numberWithCommas(response.data.credit);
          }
          this.loading = false;
        });
      }
    },
    searchData(){
        this.games = _.filter(this.gamesAll,(obj) => {
            return obj.GameName.toLowerCase().startsWith(this.search.toLowerCase());
        });
    },
    showGames(type){
        this.games = _.filter(this.gamesAll,(obj) => {
            return obj.GameName.toLowerCase().startsWith(this.search.toLowerCase());
        });
        if(type === 'Favorite'){
            this.games = _.filter(this.games,(obj) => {
              for(const x in this.favorites){
                if(obj.GameCode === this.favorites[x]){
                  return obj;
                }
              }
            });
        }else
        if(type){
            this.games = _.filter(this.games,(obj) => {
                return obj.GameType.toLowerCase().startsWith(type.toLowerCase());
            });
        }
    }
  }
};
</script>
<style scoped>
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>