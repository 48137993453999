<template>
  <div>
    <div class="center" v-if="showLoading">
		<lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	</div>
     <div class="home" v-else>
        <div class="gl-header">
          <nav class="navbar navbar-fixed-top">
            <div class="container">
              <div id="site-logo" class="navbar-header" style="width:100%;"><!-- align-items: center;-->
                <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px; flex-direction: row;display: flex;">
                  <div style="display: flex; flex: 1;">
                    <a class="navbar-brand" @click="goto('home')" title="back to home page">
                    <div style="display: flex; align-items: center;height:10px;justify-content:flex-end">
                      <span v-if="this.web && this.web.name" style="font-weight: 800; color: #fff; padding-top: 40px; font-size: 20px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif; margin-left: 10px;" class="p2">{{this.web.name.toUpperCase()}}</span>
                    </div>
                    </a>
                  </div>
                  <div>
                  <div style="display: flex; align-items: center;justify-content:flex-end">
                    <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                      <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                      <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 20px 4px 6px; font-size: 14px;">{{point}}</span>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                    <span v-if="this.code" style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                  </div>
                  </div>
                  <div style="padding-left:10px;margin-top:-5px;">
                    <img src="../assets/images/logout.png" style="height:40px;cursor:pointer" @click="logout">
                  </div>
                </div>
              </div>
            </div>
          </nav>
		    </div>
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                <div class="ui stackable grid wprw_width100" style="margin-top:70px;">
                    <div id="wprw_popup_wheel_container_left" class="ten wide column middle aligned ">
                        <div class="wprw-spinner wprw_wheel_popup_size">
                            <div class="wprw-ball"> <span></span></div>
                            <div class="wprw-platebg"></div>
                            <div id="wprw-toppart" class="wprw-topnodebox">
                                <div class="wprw-platetop"></div>
                                <div class="wprw-topparttop2">
                                    <div class="wprw-silvernode"></div>
                                    <div class="wprw-topnode wprw-silverbg"></div> <span
                                        class="wprw-top wprw-silverbg"></span> <span
                                        class="wprw-right wprw-silverbg"></span> <span
                                        class="wprw-down wprw-silverbg"></span> <span
                                        class="wprw-left wprw-silverbg"></span>
                                </div>
                            </div>
                            <div id="wprw-rcircle" class="wprw-pieContainer">
                                <div class="wprw-pieBackground"></div>
                            </div>
                        </div>
                    </div>
                    <div id="wprw_popup_wheel_container_right" class="six wide column middle aligned">
                        <form id="wprw_main_popup_form" class="ui large form" method="post" action=""> <input type="hidden" name="wprw_checksubmitted" value="1">
                            <button id="wprw-btnSpin" class="ui button" style="background: linear-gradient(rgb(254, 221, 91) 10%, rgb(254, 140, 6));" type="submit" :disabled="!canOpen"> หมุนได้เลย</button>
                        </form>
                    </div>
                </div>
            </div>
	      </main>
<!--
        <div class="x-button-actions" id="account-actions-mobile" style="position: fixed; left: 0; right: 0; bottom: 0;">
                <div class="d-flex">
                    <div class="-btn-container -withdraw-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="EZ Slot ถอนเงิน" class="-icon img-fluid">
                            <div class="-typo">ถอนเงิน</div>
                        </a>
                    </div>
                    <div class="-btn-container -lobby-container">
                        <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
                            <div class="-img-container">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                            </div>
                            <div class="-typo">หน้าเกมส์</div>
                        </a>
                    </div>
                    <div class="-btn-container -deposit-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#depositChoosePromotionModal" class="btn btn-lg btn-plain-secondary js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="EZ Slot ฝากเงิน" class="-icon img-fluid">
                            <div class="-typo">ฝากเงิน</div>
                        </a>
                    </div>
                </div>
            </div>
-->

        <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('website')">
            <img :src="logoLeft" alt="ทางเข้าเล่น" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;">
            <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ทางเข้าเล่น</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;cursor:pointer;" @click="goto('home')">
            <div>
                <img class="animate__animated animate__pulse animate__infinite" src="../assets/images/game-console.png" style="width: 105px; height: 105px; margin-bottom: 10px;" alt="เข้าเล่น Lobby">
            </div>
            <!-- <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div> -->
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('contact')">
            <img :src="logoRight" alt="ติดต่อ" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;margin-left: 18px;">
             <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ติดต่อ</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Lottie from './lottie.vue'
import "../assets/css/semantic.min.css";
import "../assets/css/wproulettewheel.css";
import "../assets/css/wheel.css";
import * as animateSantaWalk from '../assets/loading.json'
import '../assets/js/jquery.keyframes.js'
const audio = new Audio(require('../assets/RouletteWheel1.mp3'));
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Roulet',
  created () {
    document.body.style.backgroundColor = '#000'
    const animateSplashScreen = animateSantaWalk.default
    this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/login')
    } else {
      this.getUserId()
      this.animSplashScreen.play()
      setTimeout(() => {
        this.showLoading = false
        this.animSplashScreen.stop()
        setTimeout(() => {
            this.numbersLocation = this.createWheel();
            window.jQuery("#wprw_input_form").submit((e) => {
                // Prevent actually submitting the form
                e.preventDefault();
                this.startWheelSequence('website');
            });

            window.jQuery("#wprw_main_popup_form").submit((e)=>{
                //Prevent actually submitting the form
                e.preventDefault();
                this.startWheelSequence('website');
                audio.volume=0;
                audio.play();
                audio.pause();
            });
        }, 200);
      }, 3500)
    }
    this.getGames()
  },
  data () {
    return {
      logoLeft:'',
      logoRight:'',
      angle:0,
      gameKey: 'roulet',
      showLoading: true,
      pictureUrl: '',
      code: '',
      user: [],
      games_web: [],
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
        classes: 'fadeIn',
        duration: 5000,
        iteration: 'infinite'
      },
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext:'น้องออโต้กำลังประมวลผลให้อยู่ค่ะ รอสักครู่',
      credit:'',
      save:false,
      canvas:null,
      frame:null,
      wheels:null,
      sections : [],
      sectionspoint: [],
      colors : [],
      percent: [],
      numorder:[],
      numbersLocation:[],
    }
  },
  components: {
    lottie: Lottie,
  },
  methods: {
    logout(){
        this.$confirm(
          {
            message: `ต้องการล็อกเอาท์จากระบบ`,
            button: {
              yes: 'Yes',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                firebase.auth().signOut().then(()=>{
                  router.replace('/login')
                })
              }
            }
          }
        );
    },
    randomFixed(){
        const item = this.percent[Math.floor(Math.random() * this.percent.length)]
        const rands = []
        for(const x in this.numorder) {
            if(this.numorder[x] === item){
                // console.log('this.numorder[x]='+this.numorder[x]+'=x='+x+'=item='+item);
                rands.push(x);
            }
        }
        const rand = Math.floor(Math.random() * rands.length);
        // console.log(rands[rand]);
        return rands[rand];
    },
    startWheelSequence(integration){
      this.canOpen=false;
      //window.jQuery('#wprw-btnSpin').attr('disabled','true');
      //let dataform = '';
      // if (integration === 'popup'){
      // 	dataform += window.jQuery("#wprw_main_popup_form").serialize();
      // } else if (integration === 'website') {
      // 	dataform += window.jQuery("#wprw_input_form").serialize();
      // }
      // dataform += '&action=handlepermissionrequest';
      // dataform += '&security=7a7bbe6a7c';  // Add nonce security to request
        this.beginWheelSpin(integration); 
    },
    playSound() {
		audio.volume=0.6;
		audio.play();
	},
  resetAnimation() {
		const animationPlayState = "animation-play-state";
		const playStateRunning = "running";

		const pfx = window.jQuery.keyframe.getVendorPrefix();
		 window.jQuery(".wprw-ball").css(pfx + animationPlayState, playStateRunning).css(pfx + "animation", "none");
		 window.jQuery(".wprw-pieContainer").css(pfx + animationPlayState, playStateRunning).css(pfx + "animation", "none");
		 window.jQuery("#wprw-toppart").css(pfx + animationPlayState, playStateRunning).css(pfx + "animation", "none");

		 window.jQuery("#rotate2").html("");
		 window.jQuery("#rotate").html("");
	},
  spinTo(num) {
    //get location
    const temp = this.numbersLocation[num][0] + 4;
    //randomize
    const randomSpace = Math.floor(Math.random() * 360 + 1);

    this.resetAnimation();
    setTimeout(()=> {
      this.bgRotateTo(randomSpace);
      this.ballRotateTo(randomSpace + temp);
      setTimeout(this.playSound(),500);
    }, 500);
  },
  bgRotateTo(deg) {
      const numbg = window.jQuery(".wprw-pieContainer");
      const toppart = window.jQuery("#wprw-toppart");
      const wheelSpinTime = 2;
      const rotationsTime = 8;
      const destination = 360 * wheelSpinTime + deg;
      const temptime = (rotationsTime * 1000) / 1000 + 's';
      window.jQuery.keyframe.define({
        name: "rotate",
        from: {
          transform: "rotate(0deg)"
        },
        to: {
          transform: "rotate(" + destination + "deg)"
        }
      });
      window.jQuery.keyframe.define({
        name: "rotateEnd",
        from: {
          transform: "rotate(" + destination + "deg)"
        },
        to: {
          transform: "rotate(" + (destination + 700) + "deg)"
        }
      });
      window.jQuery(numbg).playKeyframe({
        name: "rotate", 
        duration: temptime, 
        timingFunction: "ease-in",
      });
      window.jQuery(toppart).playKeyframe({
        name: "rotate", 
        duration: temptime, 
        timingFunction: "ease-in",
      });
      setTimeout(function(){
        window.jQuery(numbg).playKeyframe({
          name: "rotateEnd", 
          duration: '7s', 
          timingFunction: "ease-out",
        });
        window.jQuery(toppart).playKeyframe({
          name: "rotateEnd", 
          duration: '7s', 
          timingFunction: "ease-out",
        });
      }, 8000);
	},
  ballRotateTo(deg) {
		const ballSpinTime = 4;
		const rotationsTime = 8;
		const destination = -360 * ballSpinTime - (360 - deg);
		window.jQuery.keyframe.define({
			name: "rotate2",
			from: {
				'transform': "rotate(0deg) translateY(0em)"
			},
			to: {
				'transform': "rotate(" + destination + "deg) translateY(5em)"
			}
		});

		window.jQuery.keyframe.define({
			name: "rotateBallStart",
			from: {
				transform: "rotate(0deg) translate(0px, 0px)"
			},
			to: {
				transform: "rotate(-2880deg) translate(0px, 0px)"
			}
		});

		window.jQuery.keyframe.define({
			name: "rotateBallEnd",
			from: {
				transform: "rotate(" + destination + "deg) translateY(5em)"
			},
			to: {
				transform: "rotate(" + (destination + 700) + "deg) translateY(5em)"
			}
		});
		const ballbg = window.jQuery(".wprw-ball");
		
		window.jQuery(ballbg).playKeyframe({
				name: "rotateBallStart", 
				duration: '4s', 
				timingFunction: "cubic-bezier(0.15,0,1,1)", 
		});
		setTimeout(function(){
			window.jQuery(ballbg).playKeyframe({
				name: "rotate2", 
				duration: (rotationsTime - 4) + 's', 
				timingFunction: "ease-out", 
			});
		}, 4000);
		setTimeout(function(){
			window.jQuery(ballbg).playKeyframe({
				name: "rotateBallEnd", 
				duration: '7s', 
				timingFunction: "ease-out",
			});
		}, 8000);
  },
    winFunction(){
      setTimeout(()=>{
        if(this.numorder[this.rand] == 0){
          this.$confirm(
            {
              message: `🏟 TT เศร้าสุดๆ 😘😘`,
              button: {
              yes: 'OK'
              },
              callback: async (confirm) => {
                if (confirm) {
                  router.replace('/games')
                }
              }
            }
                );	
        }else{
          this.$confirm(
            {
              message: `🏟 ปังปุรีเย่!! คุณได้รับ ${this.numorder[this.rand]} ${this.games_web.type}😘😘`,
              button: {
              yes: 'OK'
              },
              callback: async (confirm) => {
                if (confirm) {
                  router.replace('/games')
                }
              }
            }
                );	
        }
	  	}, 15500);
    },
    beginWheelSpin(integration){
      this.rand = this.randomFixed();
      const item = this.numorder[this.rand];
      setTimeout(() => {
              // Check against Win Probability
        this.spinTo(this.numorder[this.rand]);
        this.winFunction();
      }, 500);
      setTimeout(() => {
        if(this.games_web.type === 'credit'){
              firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                  [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
              })
          }else{
              const update = {}
              this.point = this.point + Number(item)
              update[`point`] = firebase.database.ServerValue.increment(Number(item))
              update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
              firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
          }
          firebase.firestore().collection('transections').add({
              code: this.user.code,
              mobile: this.user.mobile,
              createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
              game: this.gameKey,
              game_name: this.games_web.name,
              qty: item,
              type: this.games_web.type,
              status: (this.games_web.type === 'credit')?false:true,
              web: this.$projectId
          })
      },4000);

      // Remove Form if integration is Pop-up
      if (integration === 'popup'){
        window.jQuery("#wprw_main_popup_form, #wprw_popup_header_container ").css('opacity', 0);
        setTimeout(() => {
          window.jQuery("#wprw_main_popup_form, #wprw_popup_header_container").css('display','none');
          //Bring wheel to center, if wheel is sideways (width > 768 );
          if ( window.jQuery(window).width() > 768 ) {
            window.jQuery("#wprw_popup_wheel_container_right").css('display','none');
            window.jQuery(".wprw-spinner").css('transform', 'translateX(30%)');
            setTimeout(() => {
              // Move Back the wheel when the animation ends
              this.moveWheelBack();
            }, 10000);
          }
        }, 500);
      }
    },
    moveWheelBack(){
		// if wheel was previously moved, move it back
		setTimeout(function(){
			jQuery(".wprw-spinner").css('transform', '');
			setTimeout(function(){
				//jQuery("#wprw_popup_wheel_container_right").css('display','flex');
				//jQuery(".wprw-spinner").removeAttr('style');
			},500);
		},500);
	},
    createWheel() {
		const temparc = 360 / this.numorder.length;
		for (let i = 0; i < this.numorder.length; i++) {
			this.numbersLocation[this.numorder[i]] = [];
			this.numbersLocation[this.numorder[i]][0] = i * temparc;
			this.numbersLocation[this.numorder[i]][1] = i * temparc + temparc;

			const newSlice = document.createElement("div");
			window.jQuery(newSlice).addClass("wprw-hold");
			const newHold = document.createElement("div");
			window.jQuery(newHold).addClass("wprw-pie");
			const newNumber = document.createElement("div");
			window.jQuery(newNumber).addClass("wprw-num");

			newNumber.innerHTML = this.numorder[i];
			window.jQuery(newSlice).attr("id", "rSlice" + i);
			window.jQuery(newSlice).css("transform","rotate(" + this.numbersLocation[this.numorder[i]][0] + "deg)");

			window.jQuery(newHold).css("transform", "rotate(12.73deg)");
			window.jQuery(newHold).css("-webkit-transform", "rotate(12.73deg)");
            
            if(i === 0){
                window.jQuery(newHold).addClass("wprw-greenbg");
            }else if(i%2 === 1){
                window.jQuery(newHold).addClass("wprw-redbg");
            }else{
                window.jQuery(newHold).addClass("wprw-greybg");
            }
			// if (window.jQuery.inArray(this.numorder[i], numgreen) > -1) {
			// 	window.jQuery(newHold).addClass("wprw-greenbg");
			// } else if (window.jQuery.inArray(this.numorder[i], numred) > -1) {
			// 	window.jQuery(newHold).addClass("wprw-redbg");
			// } else if (window.jQuery.inArray(this.numorder[i], numblack) > -1) {
			// 	window.jQuery(newHold).addClass("wprw-greybg");
			// } 

			window.jQuery(newNumber).appendTo(newSlice);
			window.jQuery(newHold).appendTo(newSlice);

			const rinner = window.jQuery("#wprw-rcircle");
			window.jQuery(newSlice).appendTo(rinner);
		}
		return this.numbersLocation;
	},
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    async getGames () {
      this.games_web = (await firebase.database().ref(`/games_web/${this.$projectId}/${this.gameKey}`).once('value')).val()
      firebase.database().ref(`/games/${this.gameKey}`).once('value').then((snapshot) => {
        if (!snapshot.val().status || !this.games_web.status) {
         // router.replace('/games')
        }
      })
      if(this.games_web){
          for(let i=1;i<=29;i++){
              this.numorder.push((this.games_web[`spin${i}`])?this.games_web[`spin${i}`]:0)
              if(this.games_web[`spin${i}percent`]){
                for(let p=1;p<=this.games_web[`spin${i}percent`];p++){
                    this.percent.push(this.games_web[`spin${i}`]);
                }
              }
          }
      }
    },
    async getUserId () {
        if(!this.web || !this.web.name)
            this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
        if (this.web) {
            if(this.web.login_image){
                let width = screen.width;
                if(width <= 480 && this.web.login_image_mobile)
                    this.pictureUrl = this.web.login_image_mobile;
                else
                    this.pictureUrl = this.web.login_image;
            }else
                this.pictureUrl = require('../assets/images/2092839.jpg')
            if(this.web.img_left)
                this.logoLeft = this.web.img_left
            else
                this.logoLeft = require('../assets/images/casino.png')
            if(this.web.img_right)
                this.logoRight = this.web.img_right
            else
                this.logoRight = require('../assets/images/contact.png')
            const email = firebase.auth().currentUser.email.split('@')
            const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
            this.user = users[Object.keys(users)[0]]
            this.user.key = Object.keys(users)[0]
            if (this.user) {
                this.point = this.user.point
                this.code = this.user.code
                this.permission = this.user.games[this.gameKey]
                if (this.permission > 0) {
                    this.canOpen = true
                    this.running = false
                } else {
                    this.canOpen = false
                }
            } else {
                router.replace('/login')
            }
            var styleElem = document.head.appendChild(document.createElement('style'))
            styleElem.innerHTML = `#contact_gl:before {background: ${this.web.login_color};}`
        } else {
            router.replace('/login')
        }
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
.spin {   padding: 0px;
          margin: 0px;
          overflow: hidden;
          height: 100%;
/*background: rgb(255,204,92,1);
background: radial-gradient(circle, rgba(255,204,92,1) 49%, rgba(34,193,195) 100%);*/
      }
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>
