<template>
  <div>
     <div class="home">
        <div class="gl-header">
          <nav class="navbar navbar-fixed-top">
            <div class="container">
              <div id="site-logo" class="navbar-header" style="width:100%;margin-left: 0px;"><!-- align-items: center;-->
                <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px; flex-direction: row;display: flex;">
                  <div style="display: flex; flex: 1;">
                    <a class="navbar-brand" @click="goto('home')" title="back to home page">
                    <div style="display: flex; align-items: center;height:10px;justify-content:flex-end">
                      <span v-if="this.web && this.web.name" style="font-weight: 800; color: #fff; padding-top: 40px; font-size: 20px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.web.name.toUpperCase()}}</span>
                    </div>
                    </a>
                  </div>
                  <div>
                  <div style="display: flex; align-items: center;justify-content:flex-end">
                    <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                      <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                      <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 20px 4px 6px; font-size: 14px;">{{point}}</span>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                    <span v-if="this.code" style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                  </div>
                  </div>
                  <div style="padding-left:15px;margin-top:-5px;">
                    <img src="../assets/images/logout.png" style="height:40px;cursor:pointer" @click="logout">
                  </div>
                </div>
              </div>
            </div>
          </nav>
		    </div>
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                <div class="container">
                    <table id="customers">
                        <tr>
                            <th>เกมส์</th>
                            <th>จำนวน</th>
                            <th>เวลา</th>
                            <th>สถานะ</th>
                        </tr>
                        <tr v-for="(item, index) in rows" :key="index">
                            <td>{{item.game_name}}</td>
                            <td>{{item.qty}} {{item.type}}</td>
                            <td>{{item.showdate}}</td>
                            <td v-if="!item.status" align="center"><img src="../assets/images/waiting.png" width="30"></td>
                            <td align="center" v-else><img src="../assets/images/done.png" width="30"></td>
                        </tr>
                    </table>
                </div>
            </div>
	      </main>
        <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('website')">
            <img :src="logoLeft" alt="ทางเข้าเล่น" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px">
            <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ทางเข้าเล่น</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;cursor:pointer;" @click="goto('home')">
            <div>
                <img class="animate__animated animate__pulse animate__infinite" src="../assets/images/game-console.png" style="width: 105px; height: 105px; margin-bottom: 10px;" alt="เข้าเล่น Lobby">
            </div>
            <!-- <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div> -->
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('contact')">
            <img :src="logoRight" alt="ติดต่อ" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;margin-left: 18px;">
             <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ติดต่อ</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Huay',
  created () {
    //document.body.style.backgroundColor = '#000'
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/login')
    } else {
      this.getUserId()
    }
  },
  data () {
    return {
        logoLeft:'',
        logoRight:'',
        rows:[],
        code:'',
        web:'',
        point:0,
        pictureUrl: '',
    }
  },
  methods: {
    logout(){
        this.$confirm(
          {
            message: `ต้องการล็อกเอาท์จากระบบ`,
            button: {
              yes: 'Yes',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                firebase.auth().signOut().then(()=>{
                  router.replace('/login')
                })
              }
            }
          }
        );
    },
    async getUserId () {
      this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
      if (this.web) {
        if(this.web.login_image){
          let width = screen.width;
          if(width <= 480 && this.web.login_image_mobile)
            this.pictureUrl = this.web.login_image_mobile;
          else
            this.pictureUrl = this.web.login_image;
        }else
          this.pictureUrl = require('../assets/images/2092839.jpg')
        if(this.web.img_left)
          this.logoLeft = this.web.img_left
        else
          this.logoLeft = require('../assets/images/casino.png')
        if(this.web.img_right)
          this.logoRight = this.web.img_right
        else
          this.logoRight = require('../assets/images/contact.png')
        const email = firebase.auth().currentUser.email.split('@')
        const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
        this.user = users[Object.keys(users)[0]]
        this.user.key = Object.keys(users)[0]
        if (this.user) {
          this.point = this.user.point
          this.code = this.user.code
          this.permission = this.user.games[this.gameKey]
          firebase.firestore().collection('transections').where('web','==',this.$projectId).where('code','==',this.user.code).orderBy('createdate','desc').limit(100).get().then((snapshot)=>{
              const rows = []
              snapshot.forEach((doc)=>{
                  const row = doc.data()
                  if(!row.game_name){
                      console.log(doc.id)
                  }
                  row.showdate = moment(row.createdate).format('D,MMM HH:mm');
                  rows.push(row)
              })
              this.rows = rows
          })
        } else {
          router.replace('/login')
        }
        var styleElem = document.head.appendChild(document.createElement('style'))
        styleElem.innerHTML = `#contact_gl:before {background: ${this.web.login_color};}`
      } else {
        router.replace('/login')
      }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    }
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 130px;
  font-size: 13px;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}
#customers tr:nth-child(odd){background-color: #fff;}

#customers tr:hover {background-color: #fff;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
