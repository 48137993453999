<template>
  <div class="limiter">
		<div class="container-login100" :style="{ 'background-image': `url(${pictureUrl})`}">
			<div class="wrap-login100 p-t-120 p-b-30">
					<div class="login100-form-avatar">
						<img :src="logo" alt="AVATAR">
					</div>

					<span class="login100-form-title p-t-20 p-b-45">
						{{this.name}}
					</span>
          <span v-if="error" style="font-family: Montserrat-ExtraBold;font-size: 18px;color: #ce2121;line-height: 1;text-align: center;width: 100%;display: block;padding-bottom: 15px;">{{error}}</span>
					<div class="wrap-input100 validate-input m-b-10" data-validate = "Mobile is required">
						<input class="input100" type="number" v-model="mobile" id="mobile" placeholder="เบอร์มือถือ" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-mobile"></i>
						</span>
					</div>

					<div class="wrap-input100 validate-input m-b-10" data-validate = "Password is required">
						<input class="input100" type="password" v-model="pass" id="pass" placeholder="รหัสผ่าน">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock"></i>
						</span>
					</div>
          
          <div class="text-center validate-input m-b-10">
						<input type="checkbox" v-model="change" @click="checkShowPassword()"><label for="c2" style="color:#FFFFFF;">Show password</label>
						<span class="focus-input100"></span>
					</div>

					<div class="container-login100-form-btn p-t-10">
						<button class="login100-form-btn" @click="login">
							Login
						</button>
					</div>

					<div class="text-center w-full p-t-25 p-b-100">
						<a :href="link" class="txt1">
							Forgot Password?
						</a>
					</div>

					<div class="text-center w-full">
						<a class="txt1" href="#" @click="gotoRegister()">
							Create new account
							<i class="fa fa-long-arrow-right"></i>
						</a>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/database';
import router from "./../router";
export default {
  name: 'Login',
  data() {
    return {
      pictureUrl: '',
      name:'',
      logo:'',
      login_color:'#1e6ee8',
      error:'',
      link:'',
      mobile:'',
      pass:'',
      web:[],
      change:false,
    }
  },
  mounted(){
    this.getSetting();
  },
  methods: {
    checkShowPassword(){
      var x = document.getElementById("pass");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    gotoRegister(){
      router.replace('/register')
    },
    async login(){
      if(!this.mobile){
        $('#mobile').focus();
      }else if(!this.pass){
        $('#pass').focus();
      }else{
        if(this.mobile && this.pass){
          if(this.web){
            firebase.auth().signInWithEmailAndPassword(this.mobile.trim().toLowerCase()+this.web.email, this.pass.trim()).then(async (res) => {
                const email = firebase.auth().currentUser.email.split('@');
                const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val();
                const user = users[Object.keys(users)[0]]
                if(user){
                  window.location = '/games'
                }else{
                  this.error = 'โปรดติดต่อแอดมิน'
                }
            }).catch((error) => {
                this.error = 'โปรดติดต่อแอดมิน'
            })
          }
        }
      }
    },
    async getSetting() {
      this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val();
      if(this.web.login_image){
        let width = screen.width;
        if(width <= 480 && this.web.login_image_mobile)
        this.pictureUrl = this.web.login_image_mobile;
        else
        this.pictureUrl = this.web.login_image;
      }else
        this.pictureUrl = require('../assets/images/2092839.jpg')
      this.name = this.web.name;
      this.logo = this.web.logo;
      this.login_color = this.web.login_color;
      this.link = this.web.link;
      var styleElem = document.head.appendChild(document.createElement('style'));
      styleElem.innerHTML = `.container-login100:before {background: ${this.login_color};}`;
    },
  }
}
</script>
<style lang="scss" scoped>
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
}
</style>
<style scoped>
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

</style>