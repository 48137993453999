<template>
  <div class="limiter">
		<div class="container-login100" :style="{ 'background-image': `url(${pictureUrl})`}">
			<div class="wrap-login100 p-t-120 p-b-30">
					<div class="login100-form-avatar">
						<img :src="logo" alt="AVATAR">
					</div>

					<span class="login100-form-title p-t-20 p-b-45">
						{{this.name}}
					</span>
                    <span v-if="error" style="font-family: Montserrat-ExtraBold;font-size: 18px;color: #ce2121;line-height: 1;text-align: center;width: 100%;display: block;padding-bottom: 15px;">{{error}}</span>
					<div class="wrap-input100 validate-input m-b-10" data-validate = "Mobile is required">
						<input class="input100" type="number" v-model="mobile" id="mobile" placeholder="เบอร์มือถือ" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-mobile"></i>
						</span>
					</div>
					<div class="wrap-input100 validate-input m-b-10" data-validate = "Password is required">
						<input class="input100" type="password" v-model="pass" id="pass" placeholder="รหัสผ่าน">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock"></i>
						</span>
					</div>
                    <div class="wrap-input100 validate-input m-b-10" data-validate = "Password is required">
						<input class="input100" type="password" v-model="pass2" id="pass2" placeholder="ยืนยันรหัสผ่าน">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock"></i>
						</span>
					</div>

					<div class="container-login100-form-btn p-t-10">
						<button class="login100-form-btn" @click="register">
							สมัครสมาชิก
						</button>
					</div>

					<div class="text-center w-full p-t-25 p-b-230">
						<a href="#" @click="gotoLogin()" class="txt1">
							หน้า Login
						</a>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/database';
import router from "./../router";
import moment from 'moment-timezone';
export default {
  name: 'Login',
  data() {
    return {
      check:false,
      pictureUrl: '',
      name:'',
      logo:'',
      login_color:'#1e6ee8',
      error:'',
      link:'',
      mobile:'',
      pass:'',
      pass2:'',
      code:'',
      web:[],
    }
  },
  mounted(){
    this.getSetting();
  },
  methods: {
    gotoLogin(){
        router.replace('/login')
    },
    async register(){
      if(!this.mobile){
        $('#mobile').focus();
      }else if(!this.pass){
        $('#pass').focus();
      }else if(this.pass !== this.pass2){
        this.error = 'รหัสผ่านไม่เหมือนกันโปรดตรวจสอบ'
      }else{
        if(this.mobile && this.pass && this.web){
            const user = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(this.mobile.toString().trim()).once('value')).val();
            if(user){
                this.error = 'มีเบอร์นี้ในระบบแล้วโปรดติดต่อ Admin'
            }else if(!this.check){
              this.check = true;
              this.error = 'ระบบกำลังประมวลผล';
              let mobile = this.mobile
              if(this.web.delete3digit){
                mobile = mobile.toString().substring(3, 10)
              }
                firebase.database().ref(`/users_created`).push({
                    mobile:this.mobile,
                    password:this.pass.toString().trim(),
                    code:(this.web.prefix)?this.web.prefix+''+mobile:mobile,
                    createdate:moment().tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss"),
                    updatedate:moment().tz("Asia/Bangkok").format("YYYY-MM-DD HH:mm:ss"),
                    point:0,
                    web:this.$projectId
                }).then(()=>{
                  setTimeout(() => {
                    this.$confirm({
                    message: `สมัครสมาชิกเรียบร้อยแล้วค่ะ`,
                    button: {
                        yes: 'OK'
                    },
                    callback: async (confirm) => {
                        if (confirm) {
                            router.replace('/login')
                        }
                    }
                    })
                  }, 3000);
                });
            }
        }
      }
    },
    async getSetting() {
      this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val();
      if(this.web.login_image){
        let width = screen.width;
        if(width <= 480 && this.web.login_image_mobile)
        this.pictureUrl = this.web.login_image_mobile;
        else
        this.pictureUrl = this.web.login_image;
      }else
        this.pictureUrl = require('../assets/images/2092839.jpg')
      this.name = this.web.name;
      this.logo = this.web.logo;
      this.login_color = this.web.login_color;
      this.link = this.web.link;
      var styleElem = document.head.appendChild(document.createElement('style'));
      styleElem.innerHTML = `.container-login100:before {background: ${this.login_color};}`;
    },
  }
}
</script>
<style scoped>
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}

</style>