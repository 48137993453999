<template>
  <div>
    <div class="center" v-if="showLoading">
		<lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	</div>
     <div class="home" v-else>
        <div class="gl-header">
          <nav class="navbar navbar-fixed-top">
            <div class="container">
              <div id="site-logo" class="navbar-header" style="width:100%;margin-left: 0px;"><!-- align-items: center;-->
                <div class="-user-balance js-user-balance f-sm-6 f-7 " style="padding-top:5px; flex-direction: row;display: flex;">
                  <div style="display: flex; flex: 1;">
                    <a class="navbar-brand" @click="goto('home')" title="back to home page">
                    <div style="display: flex; align-items: center;height:10px;justify-content:flex-end">
                      <span v-if="this.web && this.web.name" style="font-weight: 800; color: #fff; padding-top: 40px; font-size: 20px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.web.name.toUpperCase()}}</span>
                    </div>
                    </a>
                  </div>
                  <div>
                  <div style="display: flex; align-items: center;justify-content:flex-end">
                    <div style="background: #e7dcef; height: 32px !important; align-items: center; display: flex; border-radius: 20px;">
                      <img style="width: 30px; height: 30px" src="../assets/images/ez-slot-ic-coin.png" alt="customer image">
                      <span style="font-weight: 500; color: rgb(71, 44, 89); padding: 4px 20px 4px 6px; font-size: 14px;">{{point}}</span>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center;height:10px;padding-top:15px;justify-content:flex-end">
                    <span v-if="this.code" style="font-weight: 500; color: #fff; padding: 4px 10px 4px 6px; font-size: 14px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">{{this.code.toUpperCase()}}</span>
                  </div>
                  </div>
                  <div style="padding-left:15px;margin-top:-5px;">
                    <img src="../assets/images/logout.png" style="height:40px;cursor:pointer" @click="logout">
                  </div>
                </div>
              </div>
            </div>
          </nav>
		    </div>
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section" :style="{ 'background-image': `url(${pictureUrl})`}">
                <div class="spin">
                    <canvas id="canvas" width="2355" height="521"></canvas>
                        <!-- <div style="position: absolute; bottom: 70px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                        <lottie :options="defaultOptionsSanta" :height="300" :width="300" v-on:animCreated="handleAnimationSanta"/>
                        </div>
                        <div style="position: absolute; top: 80px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                            <lottie :options="defaultOptions2" :height="300" :width="300" v-on:animCreated="handleAnimation2"/>
                        </div>
                        <div style="position: absolute; top: 80px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;" @click="openGift">
                        <lottie :options="defaultOptions" :height="300" :width="300" v-on:animCreated="handleAnimation"/>
                        </div> -->

                </div>
            </div>
	      </main>
<!--
        <div class="x-button-actions" id="account-actions-mobile" style="position: fixed; left: 0; right: 0; bottom: 0;">
                <div class="d-flex">
                    <div class="-btn-container -withdraw-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#withdrawModal" class="btn btn-lg btn-plain-primary  js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_withdraw_mobile.png" alt="EZ Slot ถอนเงิน" class="-icon img-fluid">
                            <div class="-typo">ถอนเงิน</div>
                        </a>
                    </div>
                    <div class="-btn-container -lobby-container">
                        <a href="/%E0%B8%AA%E0%B8%A5%E0%B9%87%E0%B8%AD%E0%B8%95" class="btn btn-lg btn-plain-lobby">
                            <div class="-img-container">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile.png" class="-icon img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                                <img src="../assets/images/ez-slot-ic_lobby_mobile_play.png" class="-icon-play img-fluid" alt="EZ Slot เข้าเล่น Lobby">
                            </div>
                            <div class="-typo">หน้าเกมส์</div>
                        </a>
                    </div>
                    <div class="-btn-container -deposit-container">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#depositChoosePromotionModal" class="btn btn-lg btn-plain-secondary js-account-approve-aware">
                            <img src="../assets/images/ez-slot-ic_deposit_mobile.png" alt="EZ Slot ฝากเงิน" class="-icon img-fluid">
                            <div class="-typo">ฝากเงิน</div>
                        </a>
                    </div>
                </div>
            </div>
-->

        <div style="position: fixed; left: 0; right: 0; bottom: 0; display: flex; z-index: 99; align-items: flex-end;" >
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-left-radius: 30px; display: flex; justify-content: center; height: 58px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('website')">
            <img :src="logoLeft" alt="ทางเข้าเล่น" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px">
            <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ทางเข้าเล่น</div>
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#fedd5b 10%,#fe8c06); border-top-right-radius: 25px; border-top-left-radius: 25px; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%);z-index: 1;height: 80px; display: flex; flex-direction: column; align-items: center; justify-content: flex-end;cursor:pointer;" @click="goto('home')">
            <div>
                <img class="animate__animated animate__pulse animate__infinite" src="../assets/images/game-console.png" style="width: 105px; height: 105px; margin-bottom: 10px;" alt="เข้าเล่น Lobby">
            </div>
            <!-- <div style="font-weight: 800; color: #fff; margin-top: -13px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;" class="p2">หน้าเกมส์</div> -->
          </div>
          <div style="flex: 1; background: linear-gradient( 180deg,#c1e9fe 10%,#56aff0); border-top-right-radius: 30px; display: flex; justify-content: center; height: 58px; justify-content: center; box-shadow: 0 0 15px 0 rgb(0 0 0 / 30%); flex-direction: column; align-items: center;cursor:pointer;" @click="goto('contact')">
            <img :src="logoRight" alt="ติดต่อ" style="width: 60px; height: 58px; margin-top: -35px;margin-bottom: 8px;margin-left: 18px;">
             <div style="font-weight: 800; color: #fff; margin-top: -6px;font-family: Kanit,Helvetica Neue,Helvetica,Arial,sans-serif;">ติดต่อ</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/loading.json'
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Gift',
  created () {
    document.body.style.backgroundColor = '#000'
    const animateSplashScreen = animateSantaWalk.default
    this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/login')
    } else {
      this.getUserId()
      this.animSplashScreen.play()
      setTimeout(() => {
        this.showLoading = false
        this.animSplashScreen.stop()
        setTimeout(() => {
            this.canvas = document.getElementById('canvas')
            this.repaint(this.angle)
        },500);
      }, 3500)
    }
    this.getGames()
  },
  data () {
    return {
      logoLeft:'',
      logoRight:'',
      angle:0,
      gameKey: 'spin',
      showLoading: true,
      pictureUrl: '',
      code: '',
      user: [],
      games_web: [],
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
        classes: 'fadeIn',
        duration: 5000,
        iteration: 'infinite'
      },
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext:'น้องออโต้กำลังประมวลผลให้อยู่ค่ะ รอสักครู่',
      credit:'',
      save:false,
      canvas:null,
      frame:null,
      wheels:null,
      sections : [],
      sectionspoint: [],
      colors : [],
      percent: [],
    }
  },
  components: {
    lottie: Lottie,
  },
  methods: {
    logout(){
        this.$confirm(
          {
            message: `ต้องการล็อกเอาท์จากระบบ`,
            button: {
              yes: 'Yes',
              no: 'No'
            },
            callback: confirm => {
              if (confirm) {
                firebase.auth().signOut().then(()=>{
                  router.replace('/login')
                })
              }
            }
          }
        );
    },
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    async getGames () {
      this.games_web = (await firebase.database().ref(`/games_web/${this.$projectId}/${this.gameKey}`).once('value')).val()
      firebase.database().ref(`/games/${this.gameKey}`).once('value').then((snapshot) => {
        if (!snapshot.val().status || !this.games_web.status) {
          router.replace('/games')
        }
      })
    },
    async getUserId () {
      if(!this.web || !this.web.name)
        this.web = (await firebase.database().ref(`/web/${this.$projectId}`).once('value')).val()
      if (this.web) {
        if(this.web.login_image){
          let width = screen.width;
          if(width <= 480 && this.web.login_image_mobile)
            this.pictureUrl = this.web.login_image_mobile;
          else
            this.pictureUrl = this.web.login_image;
        }else
          this.pictureUrl = require('../assets/images/2092839.jpg')
        if(this.web.img_left)
          this.logoLeft = this.web.img_left
        else
          this.logoLeft = require('../assets/images/casino.png')
        if(this.web.img_right)
          this.logoRight = this.web.img_right
        else
          this.logoRight = require('../assets/images/contact.png')
        const email = firebase.auth().currentUser.email.split('@')
        const users = (await firebase.database().ref(`/users/${this.$projectId}`).orderByChild('mobile').equalTo(email[0]).once('value')).val()
        this.user = users[Object.keys(users)[0]]
        this.user.key = Object.keys(users)[0]
        if (this.user) {
          this.point = this.user.point
          this.code = this.user.code
          this.permission = this.user.games[this.gameKey]
          if (this.permission > 0) {
            this.canOpen = true
            this.running = false
          } else {
            this.canOpen = false
          }
        } else {
          router.replace('/login')
        }
        var styleElem = document.head.appendChild(document.createElement('style'))
        styleElem.innerHTML = `#contact_gl:before {background: ${this.web.login_color};}`
      } else {
        router.replace('/login')
      }
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/games').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
    repaint(angle) {
        if(this.games_web && this.sections.length === 0){
          let ty = 'เครดิต';
          if(this.games_web.type !== 'credit'){
              ty = 'คะแนน';
          }
          for(let j=1;j<=10;j++){
            if(this.games_web[`spin${j}slot`]){
              this.sections.push('สูตร '+this.games_web[`spin${j}`]+' ชั่วโมง');
              this.sectionspoint.push('สูตร '+this.games_web[`spin${j}`]+' ชั่วโมง');
            }else{
              this.sections.push(this.games_web[`spin${j}`]+' '+ty);
              this.sectionspoint.push(this.games_web[`spin${j}`]);
            }
            
            this.colors.push(this.games_web[`spin${j}color`]);
            if(this.games_web[`spin${j}percent`]){
                for(let p=1;p<=this.games_web[`spin${j}percent`];p++){
                  if(this.games_web[`spin${j}slot`]){
                    this.percent.push('สูตร '+this.games_web[`spin${j}`]+' ชั่วโมง');
                  }else{
                    this.percent.push(this.games_web[`spin${j}`]);
                  }
                }
            }
          }
        }
        const r = Math.min(innerWidth, innerHeight) / 2.25 | 0;
        if (this.wheels === null) {
            this.wheels = [];
            for (let selected=0; selected<this.sections.length; selected++) {
                const c = document.createElement("canvas");
                c.width = c.height = 2*r + 10;
                const ctx = c.getContext("2d"), cx = 5 + r, cy = 5 + r;
                const g = ctx.createRadialGradient(cx, cy, 0, cx, cy, r);
                g.addColorStop(0, "rgba(0,0,0,0)");
                g.addColorStop(1, "rgba(0,0,0,0.1)");
                for (let i=0; i<this.sections.length; i++) {
                    const a0 = 2*Math.PI*i/this.sections.length;
                    const a1 = a0 + 2*Math.PI/(i == 0 ? 1 : this.sections.length);
                    const a = 2*Math.PI*(i+0.5)/this.sections.length;
                    ctx.beginPath();
                    ctx.moveTo(cx, cy);
                    ctx.arc(cx, cy, r, a0, a1, false);
                    ctx.fillStyle = this.colors[i];
                    ctx.fill();
                    ctx.fillStyle = g;
                    ctx.fill();
                    ctx.save();
                    if (i == selected) {
                        ctx.fillStyle = "#FFF";
                        ctx.shadowColor = "#FFF";
                        ctx.shadowBlur = r/20;
                    } else {
                        ctx.fillStyle = "#000";
                    }
                    const check = this.sections[i].split(' ');
                    if(check[0] === 'สูตร'){
                      ctx.font = "bold " + r/this.sections.length*1.0 + "px serif";
                    }else{
                      ctx.font = "bold " + r/this.sections.length*1.2 + "px serif";
                    }
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.translate(cx, cy);
                    ctx.rotate(a);
                    ctx.fillText(this.sections[i], r*0.62, 0);
                    ctx.restore();
                }
                this.wheels.push(c);
            }
        }
        if (this.frame === null) {
            this.frame = document.createElement("canvas");
            this.frame.width = this.frame.height = 10 + 2*r*1.25 | 0;
            const ctx = this.frame.getContext("2d"), cx = this.frame.width/2, cy = this.frame.height/2;
            ctx.shadowOffsetX = r/80;
            ctx.shadowOffsetY = r/80;
            ctx.shadowBlur = r/40;
            ctx.shadowColor = "rgba(0,0,0,0.5)";
            ctx.beginPath();
            ctx.arc(cx, cy, r*1.028, 0, 2*Math.PI, true);
            ctx.arc(cx, cy, r*0.975, 0, 2*Math.PI, false);
            ctx.fillStyle = "#444";
            ctx.fill();
            ctx.shadowOffsetX = r/40;
            ctx.shadowOffsetY = r/40;
            const g = ctx.createRadialGradient(cx-r/7, cy-r/7, 0, cx, cy, r/3);
            g.addColorStop(0, "#FFF");
            g.addColorStop(0.2, "#F44");
            g.addColorStop(1, "#811");
            ctx.fillStyle = g;
            ctx.beginPath();
            ctx.arc(cx, cy, r/3.5, 0, 2*Math.PI, false);
            ctx.fill();
            ctx.translate(cx, cy);
            ctx.rotate(Math.PI - 0.18);
            ctx.beginPath();
            ctx.moveTo(- r*1.1, - r*0.07);
            ctx.lineTo(- r*0.85, 0);
            ctx.lineTo(- r*1.1, r*0.07);
            ctx.fillStyle = "#F44";
            ctx.fill();
        }
        
        this.canvas.width = innerWidth;
        this.canvas.height = innerHeight;
        const cx = innerWidth/2, cy = innerHeight/2;
        const ctx = this.canvas.getContext("2d");
        let selected = (Math.floor((- 0.2 - angle) * this.sections.length / (2*Math.PI))
                        % this.sections.length);
        if (selected < 0) selected += this.sections.length;
        ctx.save();
        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.translate(-this.wheels[selected].width/2, -this.wheels[selected].height/2)
        ctx.drawImage(this.wheels[selected], 0, 0)
        ctx.restore()
        ctx.drawImage(this.frame, cx - this.frame.width / 2, cy - this.frame.height / 2)
        if(this.canOpen){
            this.canvas.onmousedown = ()=> {
                if (!this.running) {
                  //console.log(Math.floor(Math.random() * this.percent.length));
                    const r = Math.floor(Math.random() * this.percent.length);
                    const item = this.percent[r]
                    const rands = []
                    for(const x in this.sectionspoint) {
                        if(this.sectionspoint[x] === item){
                            //console.log('this.numorder[x]='+this.sectionspoint[x]+'=x='+x+'=item='+item);
                            rands.push(x);
                        }
                    }
                    const rand = Math.floor(Math.random() * rands.length);
                    //console.log(rand);
                    let isnum = /^\d+$/.test(item);
                    if(this.games_web.type === 'credit'){
                        firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
                            [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
                        })
                    }else{
                        const update = {}
                        if(isnum){
                          this.point = this.point + Number(item)
                          update[`point`] = firebase.database.ServerValue.increment(Number(item))
                        }
                        update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
                        firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
                    }
                    let status = (this.games_web.type === 'credit')?false:true;
                    if(!isnum){
                      //สูตร 2 ชั่วโมง
                      const n = item.split(' ');
                      const games = {};
                      games['hackslot'] = {};
                      games['hackslot'].value = Number(n[1]);
                      games['hackslot'].expiredate = Number(moment().tz("Asia/Bangkok").add(n[1],'hours').format("YYYYMMDDHHmmss"));
                      games['hackslot'].expiredate2 = moment().tz("Asia/Bangkok").add(Number(n[1]),'hours').format("YYYY-MM-DD HH:mm:ss");
                      firebase.database().ref(`users/${this.$projectId}/`+this.user.key+'/games').update({
                        hackslot:games['hackslot'],
                      })
                    }
                    firebase.firestore().collection('transections').add({
                        code: this.user.code,
                        mobile: this.user.mobile,
                        createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
                        game: this.gameKey,
                        game_name: this.games_web.name,
                        qty: item,
                        type: this.games_web.type,
                        status: (isnum)?status:true,
                        web: this.$projectId
                    })
                    this.spinTo(Number(rands[rand]), 5000)
                }
            };
        }
    },
    spinTo(winner, duration) {
        this.canOpen = false;
        const finalAngle = (-0.2) - (0.5 + winner)*2*Math.PI/this.sections.length;
        const startAngle = this.angle - Math.floor(this.angle/(2*Math.PI))*2*Math.PI - 5*2*Math.PI;
        const start = performance.now();
        const frame = () => {
            const now = performance.now();
            let t = Math.min(1, (now - start) / duration);
            t = 3*t*t - 2*t*t*t; // ease in out
            this.angle = startAngle+t*(finalAngle-startAngle);
            this.repaint(this.angle);
            if (t < 1) requestAnimationFrame(frame); else {
                const coin = this.sections[winner].split(' ');
                if(Number(coin[0]) > 0){
                  //this.mycoin += Number(coin[0]);
                  this.$confirm(
                    {
                      message: `🎡 ปังปุรีเย่!! คุณได้รับ ${this.sections[winner]} 😘😘`,
                      button: {
                        yes: 'OK'
                      },
                      callback: confirm => {
                        if (confirm) {
                           this.getUserId();
                        }
                      }
                    }
                  );
                }else if(coin[0] === 'สูตร'){
                  this.$confirm(
                    {
                      message: `🎡 ปังปุรีเย่!! คุณได้รับ ${this.sections[winner]} 😘`,
                      button: {
                        yes: 'OK'
                      },
                      callback: confirm => {
                        if (confirm) {
                           this.getUserId();
                        }
                      }
                    }
                  );
                }
            }
        }
        requestAnimationFrame(frame);
        this.running = true;
    },
    
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
.spin {   padding: 0px;
          margin: 0px;
          overflow: hidden;
          height: 100%;
/*background: rgb(255,204,92,1);
background: radial-gradient(circle, rgba(255,204,92,1) 49%, rgba(34,193,195) 100%);*/
      }
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>
